import React from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import "./App.css";

class LoginForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      chat_url: "http://169.231.75.145:3001",
      values: {
        username: "",
        password: "",
      }
    };
  }

  submitForm = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("username", this.state.values.username);
    formData.append("password", this.state.values.password);

    const fetch_promise = await fetch(this.state.chat_url + "/login", {
      method: "POST",
      body: formData,
    });

    const result = await fetch_promise;
    switch (result.status) {
      case 422:
        alert("Please specify the username and password.");
        break;
      case 403:
        alert("Incorrect username or password.");
        break;
      case 201:
        const data = await result.json();
        this.props.login(
          this.state.chat_url, 
          data.token, 
          this.state.values.username
        );
        console.log("Logged in: " + data.token);
        break;
      default:
        alert("This shouldn't be possible.");
    }
  };

  handleURLChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleInputChange = (e) => {
    this.setState({
      values: { ...this.state.values, [e.target.name]: e.target.value },
    });
  };

  render() {
    return (
      <Modal show={!this.props.token}>
        <Modal.Header>
          <Modal.Title>Login</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form id="loginForm" onSubmit={this.submitForm}>
            
            <Form.Group>
              <Form.Label>Chat URL</Form.Label>
              <Form.Control 
                id="chat_url_input" 
                name="chat_url" 
                type="text" 
                value={this.state.chat_url}
                onChange={this.handleURLChange}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Username</Form.Label>
              <Form.Control 
                name="username"
                id="username_input"
                type="text"
                value={this.state.values.username}
                onChange={this.handleInputChange}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Password</Form.Label>
              <Form.Control 
                name="password"
                id="password_input"
                type="password"
                value={this.state.values.password}
                onChange={this.handleInputChange}
              />
            </Form.Group>

          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" form="loginForm" type="submit">
            Login
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default LoginForm;

/*
<Form id="loginForm">
  
  <Form.Group controlId="formBasicCheckbox">
    <Form.Check type="checkbox" label="Check me out" />
  </Form.Group>

  <Form.Group controlId="formBasicEmail">
    <Form.Label>Email address</Form.Label>
    <Form.Control type="email" placeholder="Enter email" />
  </Form.Group>

  <Form.Group controlId="formBasicPassword">
    <Form.Label>Password</Form.Label>
    <Form.Control type="password" placeholder="Password" />
  </Form.Group>
  
</Form>

*/