import "./App.css";

var bodyStyle = {
  display: 'flex',
  flexDirection: 'column',
  height: '100vh'
}

var windowStyle = {
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  minHeight: 0,
  //backgroundColor: '#f0f2f5'
  backgroundColor: 'white'
}

var footerStyle = {
  width: '100%'
};

export const Body = ({children}) => {
  return (
    <div className="body" style={bodyStyle}>
      {children}
    </div>
  );
}

export const Window = ({children}) => {
  return (
    <div className="window" style={windowStyle}>
      {children}
    </div>
  );
}

export const Footer = ({children}) => {
  return (
    <div className="footer px-3 pt-3" style={footerStyle}>
      {children}
    </div>
  );
}
