import './App.css';
import React from 'react';

const style = {
  backgroundColor: 'lightgrey',
  display: 'flex',
  alignItems: 'flex-start',
}

class UserList extends React.Component {
  
  render() {
    let users = []
    let size = 0;

    let unique = new Set();
    
    this.props.users.forEach((user) => {
      if (!unique.has(user)) {
        unique.add(user)
        users.push(<span className="ml-2 mb-0" key={user}>{user}&emsp;</span>);
        size += user.length;
      }
    });

    if (size > 40) {
      users = <marquee className="hah-you-thought-i-wouldnt-add-a-marquee-tag-didnt-you">
        {users}
      </marquee>
    }
    
    return (
      <div className="user_window p-3" style={style}>
        <h5 className="mx-2 mb-0" style={{ display: 'inline' }}>{this.props.connected? "Online": ":-( Offline"}:</h5>
        {users}
      </div>
    );
  }

}

export default UserList;
