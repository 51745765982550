import './App.css';
import React from 'react';
import { Chat, Message, Update, Tag, UnreadButton } from "./Message.js";
class MessageList extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {
      unread: true,
      onbottom: true,
      active: false,
    }
  }

  shouldComponentUpdate() {
    return this.state.active;
  }
  
  scrollToBottom = () => {
    console.log("SCROLLING");
    this.messagesEnd.scrollIntoView({ behavior: "smooth" });
    this.setState({ unread: false });
  }

  // when user manually scrolls, this event fires
  handleScroll = (event) => {
    const bottom = event.target.scrollHeight - event.target.scrollTop === event.target.clientHeight;
    if (this.state.onbottom !== bottom) {
      this.setState({ onbottom: bottom });
    }
    if (bottom) {
      console.log("BOTTOM");
      this.setState({ unread: false });
    }
  }

  componentDidMount() {
    setTimeout((() => {
      this.setState({ active: true });
      console.log("STARTING");
      this.forceUpdate();
    }).bind(this), 100);
    this.scrollToBottom();
  }

  componentDidUpdate(props) {
    // if the messages changed, scroll to bottom
    if (props.messages !== this.props.messages) {
      const fromMyself = this.props.messages[this.props.messages.length - 1].user === this.props.user;
      if (this.state.onbottom || fromMyself) {
        this.scrollToBottom();
      } else {
        this.setState({ unread: true });
      }
    }
  }
  
  render() {
    let lastUser = null;

    const messages = this.props.messages.map((message) => {
      const date = new Date(message.created * 1000);
      const timestamp = `${date.toLocaleDateString("en-US")} ${date.toLocaleTimeString("en-US")}`;
      switch (message.type) {
        case "Join":
          lastUser = null;
          return (
            <Update key={message.id}><b>{message.user}</b> has joined</Update>
          );
        case "Part":
          lastUser = null;
          return (
            <Update key={message.id}><b>{message.user}</b> has left</Update>
          );
        case "Message":
          let tag;
          if (message.user === lastUser) {
            // messages[messages.length - 1] = React.cloneElement(
            //     messages[messages.length - 1], { last: false }
            // );
          } else if (message.user !== this.props.user) {
            tag = <Tag key={message.id + "-tag"}>{message.user}</Tag>;
          }
          lastUser = message.user;
          return (
            <React.Fragment key={message.id + "-div"}>
              {tag}
              <Message mykey={message.id + "-last"} mine={message.user === this.props.user} last={true}>
                {timestamp} {message.content}
              </Message>
            </React.Fragment>
          );
        case "ServerStatus":
          lastUser = null;
          return (
            <Update key={message.id}>{timestamp} STATUS: {message.status}</Update>
          );
        default:
          return (
            <Update>Something went wrong! You suck</Update>
          );
      }
    })

    // show unread button if unread
    let unreadButton;
    if (this.state.unread) {
      unreadButton = (<UnreadButton key={"unread-button"} click={this.scrollToBottom}/>);
    }
    
    return (
      <Chat onScroll={this.handleScroll}>
        {unreadButton}
        {messages}
        <div ref={(element) => { this.messagesEnd = element; }}></div>
      </Chat>
    );
  }

}

export default MessageList;
