import React from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import "./App.css";

class Compose extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      message: ""
    }
  }

  submitForm = async (event) => {
    event.preventDefault();
    if (this.state.message) {
      const formData = new FormData();
      formData.append("message", this.state.message);

      this.setState({ message: "" });

      await fetch(this.props.chat_url + "/message", {
        method: "POST",
        body: formData,
        headers: {
          authorization: `Bearer ${this.props.token}`
        }
      });
    }
  };

  handleInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  render() {
    return (
      <Form onSubmit={this.submitForm}>
        <Form.Group>
          <InputGroup>
            <Form.Control
              id="message" 
              type="text" 
              name="message"
              value={this.state.message} 
              onChange={this.handleInputChange}
              disabled={!this.props.connected}
            />
            <InputGroup.Append>
              <Button variant="primary" type="submit">
                Send
              </Button>
            </InputGroup.Append>
          </InputGroup>
        </Form.Group>
      </Form>
    );
  }
}

export default Compose;
