import Button from 'react-bootstrap/Button';

//https://codepen.io/swards/pen/gxQmbj?editors=1100

const chatStyle = {
  fontFamily: 'helvetica',
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  overflowY: 'scroll',
}

const updateStyle = {
  color: 'darkgrey'
}

const tagStyle = {
  color: 'darkgrey',
  alignSelf: 'flex-start',
  marginLeft: '1rem',
  marginTop: '2rem',
}

const messageStyle = {
  borderRadius: '1.2rem',
  padding: '0.5rem 1rem',
  marginTop: '0.25rem',
  marginBottom: '0.25rem',
  display: 'inline-block',
  wordBreak: 'break-all'
}

const yoursStyle = {
  alignSelf: 'flex-start',
  marginRight: '25%',
  backgroundColor: '#eee',
  position: 'relative',
}

const mineStyle = {
  color: 'white',
  marginLeft: '25%',
  background: 'linear-gradient(to bottom, #00D0EA 0%, #0085D1 100%)',
  backgroundAttachment: 'fixed',
  position: 'relative',
  alignSelf: 'flex-end',
}

const lastBeforeStyle = {
  position: 'absolute',
  zIndex: 0,
  bottom: 0,
  height: '1.2rem',
  width: '1.2rem',
}

const yoursLastBeforeStyle = {
  left: '-0.5rem',
  background: '#eee',
  borderBottomRightRadius: '1rem',
}

const mineLastBeforeStyle = {
  right: '-0.5rem',
  background: 'linear-gradient(to bottom, #00D0EA 0%, #0085D1 100%)',
  backgroundAttachment: 'fixed',
  borderBottomLeftRadius: '1rem',
}

const lastAfterStyle = {
  position: 'absolute',
  zIndex: 1,
  bottom: 0,
  width: '0.6rem',
  height: '1.2rem',
  background: 'white',
}

const yoursLastAfterStyle = {
  left: '-0.6rem',
  borderBottomRightRadius: '0.5rem',
}

const mineLastAfterStyle = {
  right: '-0.6rem',
  borderBottomLeftRadius: '0.5rem',
}

const unreadButtonStyle = {
  position: 'absolute',
  bottom: '5rem',
  zIndex: 2,
}

export const Chat = (props) => {
  
  return (
    <div className="chat px-4" style={chatStyle} onScroll={props.onScroll}>
      {props.children}
    </div>
  );
}

export const Message = (props) => {
  let style;
  if (props.mine) {
    style = {...messageStyle, ...mineStyle};
  } else {
    style = {...messageStyle, ...yoursStyle};
  }

  if (props.last) {
    let beforeStyle, afterStyle;
    if (props.mine) {
      beforeStyle = {...lastBeforeStyle, ...mineLastBeforeStyle};
      afterStyle = {...lastAfterStyle, ...mineLastAfterStyle};
    } else {
      beforeStyle = {...lastBeforeStyle, ...yoursLastBeforeStyle};
      afterStyle = {...lastAfterStyle, ...yoursLastAfterStyle};
    }
    return (
      <div className="message" style={style}>
        <div style={beforeStyle}></div>
          <span>{props.children}</span>
        <div style={afterStyle}></div>
      </div>
    );
  } else {
    return (
      <div className="message" style={style}>
        <span>{props.children}</span>
      </div>
    );
  }
  
}

export const Update = (props) => {
  
  return (
    <div className="update my-2" style={updateStyle}>
      <span>{props.children}</span>
    </div>
  );

}

export const Tag = (props) => {
  
  return (
    <div className="tag" style={tagStyle}>
      <span><b>{props.children}</b></span>
    </div>
  );
  
}

export const UnreadButton = (props) => {
  return (
    <Button style={unreadButtonStyle} onClick={props.click}>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
        <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
      </svg>
    </Button>
  );
}



//{Object.assign(divStyle, divStyle2)}