import "./App.css";
import React from "react";
import Compose from "./Compose";
import LoginForm from "./LoginForm";
import MessageList from "./MessageList";
import UserList from "./UserList";
import { Body, Window, Footer } from "./Layout.js";

class App extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      connected: false,
      chat_url: null,
      token: null,
      user: null,
      users: [],
      messages: []
    };
  }

  login = (chat_url, token, user) => {
    
    this.setState({ connected: true, chat_url: chat_url, token: token, user: user });
    
    const stream = new EventSource(
        this.state.chat_url + "/stream/" + this.state.token);

    // Set initial state to list of users
    stream.addEventListener("Users", (event) => {
      console.log(event);
      this.setState({ connected: true, users: JSON.parse(event.data).users });
    });

    // Add user to state
    stream.addEventListener("Join", (event) => {
      console.log(event);
      let data = JSON.parse(event.data);
      this.setState((prevState) => ({
        users: [...prevState.users, data.user],
        messages: [...prevState.messages, {
          type: "Join",
          created: data.created,
          user: data.user,
          id: event.lastEventId,
        }]
      }));
    });

    // Delete user from state
    stream.addEventListener("Part", (event) => {
      console.log(event);
      let data = JSON.parse(event.data);
      this.setState((prevState) => ({
        users: this.state.users.filter((user) => { return user !== data.user }),
        messages: [...prevState.messages, {
          type: "Part",
          created: data.created,
          user: data.user,
          id: event.lastEventId,
        }]
      }));
    });

    // Add message to list
    stream.addEventListener("Message", (event) => {
      console.log(event);
      let data = JSON.parse(event.data);
      this.setState((prevState) => ({
        messages: [...prevState.messages, {
          type: "Message",
          created: data.created,
          user: data.user,
          content: data.message,
          id: event.lastEventId,
        }]
      }));
    });

    // Add status message to list
    stream.addEventListener("ServerStatus", (event) => {
      console.log(event);
      let data = JSON.parse(event.data);
      this.setState((prevState) => ({
        messages: [...prevState.messages, {
          type: "ServerStatus",
          created: data.created,
          status: data.status,
          id: event.lastEventId,
        }]
      }));
    });

    // Kick the user off
    stream.addEventListener("Disconnect", (event) => {
      console.log(event);
      stream.close();
      this.setState({
        connected: false,
        chat_url: null,
        token: null,
        users: [],
        messages: []
      });
    });

    stream.addEventListener("error", (event) => { 
        console.log(event);
        this.setState({ connected: false });
        if (event.target.readyState === 2) {
          this.setState({
            connected: false,
            chat_url: null,
            token: null,
            users: [],
            messages: []
          });
        }
      },
      false
    );

  }

  render() {
    let body = <></>;
    if (this.state.token) {
      body = (
        <Body>
          <Window>
            <UserList connected={this.state.connected} users={this.state.users}/>
            <MessageList messages={this.state.messages} user={this.state.user}/>
          </Window>
          <Footer>
            <Compose connected={this.state.connected} chat_url={this.state.chat_url} token={this.state.token}/>
          </Footer>
        </Body>
      );
    }

    return (
      <div className="App">
        <LoginForm token={this.state.token} login={this.login}/>
        {body}
      </div>
    );
  }
}

export default App;
